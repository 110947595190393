<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-add-cart"
    @click="handleClick"
  >
    <sui_icon_addtocart_20px size="18px" />
  </div>
</template>

<script name="AddCart" setup lang="ts">
import { computed } from 'vue'
import sui_icon_addtocart_20px from '../../components/icon/sui_icon_addtocart_20px.vue'

/**
 * 商品行加车组件
 */

// events
const emit = defineEmits(['onClick'])

interface AddCartProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 背景色
   */
  bgColor?: string
  /**
   * 边框色
   */
  borderColor?: string
}

// props
const props = withDefaults(defineProps<AddCartProps>(), {
  isShow: false,
  text: '',
  bgColor: '#fff',
  borderColor: '#000',
})

// computed
const styles = computed(() => {
  return {
    '--bg-color': props.bgColor,
    '--border-color': props.borderColor,
  }
})

// methods
const handleClick = e => {
  emit('onClick', e)
}
</script>

<style lang="less">
.bsc-cart-item-add-cart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20/37.5rem;
  padding: 0 6/37.5rem;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  border-radius: 10/37.5rem;
  width: fit-content;
}
</style>
