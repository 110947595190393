<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-find-similar-text"
    @click="handleClick"
  >
    {{ text }}
  </div>
</template>

<script name="FindSimilarText" setup lang="ts">
import { watch, computed } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

/**
 * 商品行找同款组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

// props
interface FindSimilarTextProps {
  /**
   * 是否展示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否展示金庸
   */
  isClick: boolean
  /**
   * 展示文案
   */
  text: string
}
const props = withDefaults(defineProps<FindSimilarTextProps>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  text: '',
})

// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

// events
const handleClick = () => {
  emit('onReport')
  if (props.isClick) {
    emit('onClick')
  }
}
const handleExpose = () => {
  watch(
    () => props.isShow,
    n => {
      if (n) {
        const callback = () => {
          emit('onExpose')
        }
        registerExpose('GoodsStore', callback)
      }
    },
    {
      immediate: true,
    },
  )
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-find-similar-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20/37.5rem;
  padding: 4/37.5rem 6/37.5rem;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 10/37.5rem;
  font-size: 12px;
  font-weight: 700;
  color: #222;
  opacity: var(--mask-opacity);
}
</style>
