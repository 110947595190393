<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-goods-belt"
  >
    <div class="bsc-cart-item-goods-belt__content">
      <ResizeFont
        :is-multiline="true"
        :multiline-num="2"
      >
        <ResizeFontText
          :init-font-size="12"
          :resize-font-min="8"
          :resize-font-step="1"
        >
          {{ beltText }}
        </ResizeFontText>
      </ResizeFont>
    </div>
  </div>
</template>

<script name="GoodsBelt" setup lang="ts">
import { computed } from 'vue'
import ResizeFont from '../../components/resize-font/resize-font.vue'
import ResizeFontText from '../../components/resize-font/resize-font-text.vue'

/**
 * 商品行腰带组件
 */

// props
interface GoodsBeltProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask?: boolean
  /**
   * 背景颜色
   */
  bgColor?: string
  /**
   * 字体颜色
   */
  fontColor?: string
  /**
   * 腰带文字
   */
  beltText?: string
}

const props = withDefaults(defineProps<GoodsBeltProps>(), {
  isShow: false,
  isShowMask: false,
  bgColor: 'rgba(0, 0, 0, 0.6)',
  fontColor: '#FFF',
  beltText: '',
})

// data
// computed
const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
    '--bg-color': props.bgColor,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
</script>

<style lang="less">
.bsc-cart-item-goods-belt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  color: var(--font-color);
  padding: 2/37.5rem 4/37.5rem;
  width: 100%;
  opacity: var(--mask-opacity);
  min-height: 18/37.5rem;
  font-size: 12px;
  &__content {
    overflow: hidden;
    line-height: 1;
  }
}
</style>
