<template>
  <span
    class="bsc_icon_time_16px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.93332 5.33332H8.53332V7.99998H11.7333V9.59998H6.93332V5.33332Z"
        fill="currentColor" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9333 7.99998C14.9333 4.17081 11.8292 1.06665 7.99998 1.06665C4.17081 1.06665 1.06665 4.17081 1.06665 7.99998C1.06665 11.8292 4.17081 14.9333 7.99998 14.9333C11.8292 14.9333 14.9333 11.8292 14.9333 7.99998ZM2.66665 7.99998C2.66665 5.05447 5.05447 2.66665 7.99998 2.66665C10.9455 2.66665 13.3333 5.05447 13.3333 7.99998C13.3333 10.9455 10.9455 13.3333 7.99998 13.3333C5.05447 13.3333 2.66665 10.9455 2.66665 7.99998Z"
        fill="currentColor" />
    </svg>
  </span>
</template>

<script name="sui_icon_time_16px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_time_16px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 16px);
  color: var(--color, '#000');
}
</style>
