import type { ChangeMall } from '../../../../types/mobile/index.ts'

/**
 * ChangeMall 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): ChangeMall.Props {
  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'

  return {
    isShow: data.value.aggregateProductBusiness?.changeMallInfo?.isOtherMall === '1',
    isShowMask: isNewcomerItem,
    isClick: true,
    btnText: data.value.aggregateProductBusiness?.changeMallInfo?.buttonTip,
  }
}
