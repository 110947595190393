<template>
  <span
    v-if="isShow"
    class="bsc-cart-item-goods-delete-button"
    @click="onClick"
  >
    <sui_icon_delete_18px />
  </span>
</template>

<script name="GoodsDeleteButton" setup lang="ts">
import sui_icon_delete_18px from '../../components/icon/sui_icon_delete_18px.vue'

/**
 * 商品删除组件
 */

// events
const emit = defineEmits(['onClick'])

// props
interface IGoodsDeleteButtonProps {
  isShow: boolean
}
defineProps<IGoodsDeleteButtonProps>()

const onClick = () => {
  emit('onClick')
}
</script>

<style lang="less">
.bsc-cart-item-goods-delete-button {
  width: 18/37.5rem;
  height: 18/37.5rem;
}
</style>
