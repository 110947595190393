import { template } from '@shein/common-function'
import type { ReducePriceHint } from '../../../../types/mobile/index.ts'

/**
 * ReducePriceHint 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): ReducePriceHint.Props {
  const reduceAmount = data.value.reduce_price?.reduceAmount?.amountWithSymbol
  const isInvalid = data.value.isInvalid == 1
  const isChecked = data.value.is_checked == 1
  return {
    isShow: !!reduceAmount && !isInvalid && !isChecked,
    hintText: template(
      `<b>${reduceAmount}</b>`,
      language.value.SHEIN_KEY_PWA_16323,
    ),
  }
}
