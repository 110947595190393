<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-custom-content"
    :style="styles"
    @click="handleClick"
  >
    <div class="bsc-cart-item-goods-custom-content__title">{{ title }}</div>
    <sui_icon_more_right_12px />
  </div>
</template>

<script name="GoodsCustomContent" setup lang="ts">
import { computed, withDefaults } from 'vue'
import sui_icon_more_right_12px from '../../components/icon/sui_icon_more_right_12px.vue'

/**
 * 商品行定制商品主图组件
 */

// hooks

// events
const emit = defineEmits(['onClick', 'onReport'])

// props
interface IGoodsCustomContentProps {
  /**
   * 是否展示
   */
  isShow: boolean
  /**
   * 左侧标题
   */
  title: string
  /**
   * 定制信息
   */
  data: Record<string, unknown>
  /**
   * 宽度，默认100% 单位rem
   */
  width?: string
}
const props = withDefaults(defineProps<IGoodsCustomContentProps>(), {
  width: '100%',
})

const styles = computed(() => {
  return {
    '--width': props.width,
  }
})

// events
const handleClick = () => {
  emit('onClick', props.data)
  emit('onReport', props.data)
}
</script>

<style lang="less">
.bsc-cart-item-goods-custom-content {
  width: var(--width);
  height: 30/37.5rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: #f6f6f6;
  color: #222;
  padding: 0 4/37.5rem 0 6/37.5rem;
  gap: 4/37.5rem;
  font-size: 12px;
  .bsc-cart-item-goods-custom-content__title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
