<template>
  <div
    v-if="isShow"
    :class="classes"
    :style="style"
  >
    <div class="bsc-cart-item-sfs-tag__text">{{ text }}</div>
    <template v-if="suffixText">
      <div class="bsc-cart-item-sfs-tag__suffix-line">|</div>
      <div class="bsc-cart-item-sfs-tag__suffix-text">{{ suffixText }}</div>
    </template>
  </div>
</template>

<script setup name="SFSTag" lang="ts">
import { computed } from 'vue'
import { hexToRgb } from '@shein/common-function'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 利诱文案
   */
  suffixText: string
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 最大宽度，需要单位
   */
  maxWidth?: string
}

const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  suffixText: '',
  bgColor: '',
  textColor: '',
  maxWidth: '',
})

const classes = computed(() => {
  return [
    'bsc-cart-item-sfs-tag',
  ]
})

const style = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    maxWidth: props.maxWidth,
    '--bg-color': props.bgColor,
    '--text-color': props.textColor,
    '--separator-color': hexToRgb({ hex: props.textColor, opa: 0.5 }),
  }
})
</script>

<style lang="less">
.bsc-cart-item-sfs-tag {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 16px;
  line-height: 1;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__suffix-line {
    flex-shrink: 0;
    padding: 0 2px;
    font-size: 10px;
    color: var(--separator-color);
  }
  &__suffix-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
