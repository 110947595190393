import DiscountNormalTag from './discount-normal-tag.vue'
import DiscountNormalTagV2 from './discount-normal-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useDiscountNormalTag = createHelper('DiscountNormalTag', getProps)

export {
  DiscountNormalTag,
  DiscountNormalTagV2,
  useDiscountNormalTag,
}
