<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    ref="root"
    :class="classes"
    :style="style"
    @click.stop="handleShowPopover"
  >
    <span class="bsc-cart-item-more-tag-v2__text">{{ text }}</span>
    <template v-if="disabledIcon">
      <sui_icon_doubt_16px_1
        class="bsc-cart-item-more-tag-v2__icon"
        size="12px"
      />
    </template>
    <template v-else>
      <SPopover
        v-model="showPopover"
        trigger-type="user"
        show-close-icon
        append-to-body
        :prop-style="{
          width: '64vw',
          maxWidth: '345.6px',
        }"
      >
        <sui_icon_doubt_16px_1
          slot="reference"
          class="bsc-cart-item-more-tag-v2__icon"
          size="12px"
          @click.stop="handleShowPopover"
        />
        <MoreTagContent
          v-bind="moreTagContentProps"
        />
      </SPopover>
    </template>
  </div>
</template>

<script name="MorePromotionTag" setup lang="ts">

import { ref, computed, watch } from 'vue'
import SPopover from '@shein/sui-mobile/lib/popover'
import sui_icon_doubt_16px_1 from '../../../components/icon/sui_icon_doubt_16px_1.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'
import { useScrollParent } from '../../../hooks/useScrollParent'
import { useEventListener } from '../../../hooks/useEventListener'
import MoreTagContent from './components/more-tag-content.vue'

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['onExpose'])

interface IProps {
  /**
   * 是否展示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 文案
   */
  text: string
  /**
   * Popover标题
   */
  popoverTitle: string
  /**
   * 优惠信息
   */
  items: any[]
  /**
   * 图标是否禁用点击
   */
  disabledIcon: boolean
  /**
   * 滚动的父元素
   */
  scroller?: HTMLElement
  /**
   * 最大宽度
   */
  maxWidth?: string
}

const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  popoverTitle: '',
  items: () => ([]),
  disabledIcon: false,
  maxWidth: '100%',
})

const classes = computed(() => ([
  'bsc-cart-item-more-tag-v2',
]))

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  maxWidth: props.maxWidth,
}))

const moreTagContentProps = computed(() => ({
  content: {
    title: props.popoverTitle,
    list: props.items,
  },
}))

const showPopover = ref(false)
const handleShowPopover = () => {
  showPopover.value = !showPopover.value
}

const root = ref<HTMLElement>()
const scrollParent = useScrollParent(root)
const scroller = computed(() => props.scroller || scrollParent.value)

const check = () => {
  if (showPopover.value) showPopover.value = false
}

useEventListener('scroll', check, {
  target: scroller,
  passive: true,
})

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose')
      }
      registerExpose('MoreTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-more-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  height: 16px;
  line-height: 1;
  font-size: 10px;
  color: @sui_color_discount_dark;
  background: rgba(250, 240, 238, 0.71);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    margin-left: 2px;
    height: 12px;
    cursor: pointer;
    color: @sui_color_discount_dark;
  }

  &__icon-disabled {
    cursor: auto;
  }
}
</style>
