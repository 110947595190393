<template>
  <span
    class="bsc_icon_phone_16px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.06662 11.7333C9.06662 12.1751 8.70845 12.5333 8.26662 12.5333C7.82479 12.5333 7.46662 12.1751 7.46662 11.7333C7.46662 11.2914 7.82479 10.9333 8.26662 10.9333C8.70845 10.9333 9.06662 11.2914 9.06662 11.7333Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3333 14.6666V1.33325H3.19995V14.6666H13.3333ZM9.55443 2.93325H11.7333V13.0666H4.79995V2.93325H6.97881C7.08863 3.24397 7.38496 3.46659 7.73328 3.46659H8.79995C9.14828 3.46659 9.44461 3.24397 9.55443 2.93325Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_phone_16px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_phone_16px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
