import { ref, computed, onMounted, onUnmounted, onDeactivated } from 'vue'

import type { Ref } from 'vue'

/**
 * 轮播
 */
export const useCarousel = ({ showTime, switchTime, list }: { showTime: number; switchTime: number; list: Ref<any[]> }) => {
  const index = ref(0)
  const currentIndex = computed(() => index.value % list.value.length)
  const currentItem = computed(() => list.value[currentIndex.value])
  const timer = ref<NodeJS.Timeout | null>(null)

  const start = () => {
    timer.value = setInterval(() => {
      index.value++
    }, showTime + switchTime)
  }

  const onClearTimer = () => {
    if (timer.value) {
      clearInterval(timer.value)
      timer.value = null
    }
  }

  onMounted(() => {
    if (list.value.length > 1) {
      start()
    }
  })

  onUnmounted(() => {
    onClearTimer()
  })

  onDeactivated(() => {
    onClearTimer()
  })

  return {
    index,
    currentIndex,
    currentItem,
    animationStyle: {
      '--bsc-cart-item-carousel-switch-time': `${switchTime}ms`,
    }, // 动画样式
    animationName: 'bsc-cart-item-carousel', // 动画名称
  }
}
