import { Mobile } from '../../../types/mobile'

type Platform = 'PWA' | 'H5'

type ExtractLanguageSupported<T> = {
  [P in keyof T]: T[P] extends { Language: infer L } ? (L extends never ? never : P) : never
}[keyof T]

type LanguageMappings = Pick<Mobile.Mappings, ExtractLanguageSupported<Mobile.Mappings>>

type LangMap = {
  [P in keyof LanguageMappings]: {
    [L in keyof LanguageMappings[P]['Language']]: {
      [K in Platform]?: string
    }
  }
}

const LANG: LangMap = {
  AddMore: {
    SHEIN_KEY_PWA_31963: {
      PWA: 'SHEIN_KEY_PWA_31963',
    },
  },
  GoodsAlert: {
    SHEIN_KEY_PWA_26189: {
      PWA: 'SHEIN_KEY_PWA_26189',
    },
  },
  FindNewsText: {
    SHEIN_KEY_PWA_34806: {
      PWA: 'SHEIN_KEY_PWA_34806',
    },
  },
  GoodsCustomContent: {
    SHEIN_KEY_PWA_26444: {
      PWA: 'SHEIN_KEY_PWA_26444',
    },
  },
  GoodsSaleAttr: {
    SHEIN_KEY_PWA_16112: {
      PWA: 'SHEIN_KEY_PWA_16112',
    },
  },
  IncentiveReducePrice: {
    SHEIN_KEY_PWA_31804: {
      PWA: 'SHEIN_KEY_PWA_31804',
    },
  },
  OneClickBuy: {
    SHEIN_KEY_PWA_33775: {
      PWA: 'SHEIN_KEY_PWA_33775',
    },
    SHEIN_KEY_PWA_33772: {
      PWA: 'SHEIN_KEY_PWA_33772',
    },
  },
  ReducePriceHint: {
    SHEIN_KEY_PWA_16323: {
      PWA: 'SHEIN_KEY_PWA_16323',
    },
  },
  GoodsSoldOutFlag: {
    SHEIN_KEY_PWA_16284: {
      PWA: 'SHEIN_KEY_PWA_16284',
    },
  },
}

/**
 * 获取语言映射
 */
export const getLangMap = (names:Mobile.Names[] = [], type: Platform = 'PWA') => {
  if (names.length === 0) {
    return Object.values(LANG).reduce((acc, item) => {
      Object.entries(item).forEach(([key, value]) => acc[key] = value[type])
      return acc
    }, {})
  }

  return names.reduce((acc, name) => {
    if (LANG[name]) {
      // @ts-ignore
      Object.entries(LANG[name]).forEach(([key, value]) => acc[key] = value[type])
    }
    return acc
  }, {})
}
