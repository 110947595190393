<template>
  <span
    class="bsc_icon_sold_out_36px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em;"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3029 10.8882C14.3029 8.60275 16.1556 6.75 18.4411 6.75C20.7266 6.75 22.5793 8.60275 22.5793 10.8882C22.5793 12.9667 21.047 14.6873 19.0504 14.9819V15.914C19.0504 15.9812 19.0415 16.0464 19.0249 16.1084L19.4114 16.3247C19.7967 16.5402 20.3484 16.8487 21.0144 17.2208C22.3463 17.965 24.1348 18.9635 25.9614 19.9805C29.6325 22.0245 33.4108 24.1171 33.9984 24.4036C35.1997 24.9893 35.7389 26.1371 35.556 27.202C35.3698 28.2859 34.4535 29.1512 33.105 29.1512H3.47642C2.12799 29.1512 1.2117 28.2859 1.02548 27.202C0.842535 26.1371 1.38174 24.9893 2.58304 24.4036C3.17445 24.1153 7.03229 22.0198 10.7761 19.9774C12.6404 18.9603 14.4666 17.9618 15.8267 17.2176C16.5068 16.8455 17.0703 16.537 17.4638 16.3215L17.6222 16.2347C17.5761 16.1374 17.5504 16.0287 17.5504 15.914V14.2198C17.5504 13.8056 17.8861 13.4698 18.3004 13.4698C18.3985 13.4698 18.4922 13.4887 18.5781 13.523C19.9715 13.4517 21.0793 12.2993 21.0793 10.8882C21.0793 9.43118 19.8981 8.25 18.4411 8.25C16.984 8.25 15.8029 9.43118 15.8029 10.8882C15.8029 11.3024 15.4671 11.6382 15.0529 11.6382C14.6386 11.6382 14.3029 11.3024 14.3029 10.8882ZM33.105 27.6512C33.7331 27.6512 34.0168 27.3022 34.0776 26.948C34.1417 26.5749 33.9751 26.061 33.3411 25.7519C32.7051 25.4419 28.8691 23.3162 25.2318 21.2911C23.4042 20.2736 21.615 19.2747 20.2827 18.5303C19.6165 18.1581 19.0646 17.8494 18.6792 17.6338L18.4373 17.4985L18.1843 17.6371C17.7907 17.8527 17.227 18.1613 16.5467 18.5335C15.1863 19.2779 13.3595 20.2767 11.4944 21.2942C7.77938 23.3209 3.87251 25.4437 3.24039 25.7519C2.60639 26.061 2.43972 26.5749 2.50383 26.948C2.56467 27.3022 2.84838 27.6512 3.47642 27.6512H33.105Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_sold_out_36px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '36px',
  },
  color: {
    type: String,
    default: 'inhert',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_sold_out_36px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 14px);
  color: var(--color, '#000');
}
</style>
