<template>
  <div
    v-if="isShow"
    class="bsc-goods-add-on-tip"
  >
    <div
      class="bsc-goods-add-on-tip__left"
      @click="handleClick">
      <span
        class="bsc-goods-add-on-tip__left-text"
        v-html="text"
      ></span>
      <Icon
        v-if="isAddOn && isClick"
        class="bsc-goods-add-on-tip__left-icon"
        name="sui_icon_more_right_16px"
        size="14px"
      />
    </div>
    <div class="bsc-goods-add-on-tip__right">
      <slot name="del-icon"></slot>
    </div>
  </div>
</template>

<script name="GoodsAddOnTip" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue2'

/**
 * 商品凑单提示组件
 */

// event
const emit = defineEmits(['onClick'])

// props
interface IGoodsAddOnProps {
  isShow: boolean
  text: string
  isAddOn: boolean
  isClick: boolean
}
const props = withDefaults(defineProps<IGoodsAddOnProps>(), {
  isShow: false,
  text: '',
  isAddOn: false,
  isClick: true,
})

const handleClick = () => {
  if (!props.isAddOn || !props?.isClick) return
  emit('onClick')
}
</script>

<style lang="less">
.bsc-goods-add-on-tip {
  width: 100%;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  gap: 0.32rem;
  &__left {
    flex: 1;
    display: inline-flex;
    align-items: center;
    font-size: 24/75rem;
    min-height: 44/75rem;
  }
  &__left-text {
    flex-shrink: 1;
    line-height: 28/75rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: normal;
  }
  &__left-icon {
    flex-shrink: 0;
    margin-left: 4/75rem;
    transform: rotate(0) /* rtl: rotate(-180deg)*/;
  }
}
</style>
