import FreeShippingTag from './free-shipping-tag.vue'
import FreeShippingTagV2 from './free-shipping-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useFreeShippingTag = createHelper('FreeShippingTag', getProps)

export {
  FreeShippingTag,
  FreeShippingTagV2,
  useFreeShippingTag,
}
