import type { GoodsBeltSwiper } from '../../../../types/mobile/index.ts'
import { hexToRgb } from '@shein/common-function'

/**
 * GoodsBeltSwiper 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsBeltSwiper.Props {
  const productImgBeltList = data.value.aggregateProductBusiness?.productImgBeltList || []
  const belts: GoodsBeltSwiper.Belt[] = productImgBeltList.map((item) => ({
    beltText: item.text,
    bgColor: hexToRgb({ hex: item.bgColor || '#000000', opa: item.bgColorAlpha || '0.6' }),
    fontColor: item.textColor,
  }))
  return {
    isShow: !!belts.length,
    isShowMask: false,
    belts,
  }
}
