import EstimatedPrice from './estimated-price.vue'
import EstimatedPriceV2 from './estimated-price-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useEstimatedPrice = createHelper('EstimatedPrice', getProps)

export {
  EstimatedPrice,
  EstimatedPriceV2,
  useEstimatedPrice,
}
