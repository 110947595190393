import type { FindNewsText } from '../../../../types/mobile/index.ts'
/**
 * FindNewsText 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ language }): FindNewsText.Props {
  return {
    isShow: true,
    text: language.value.SHEIN_KEY_PWA_34806,
    isOpen: false,
  }
}
