<template>
  <TransitionGroup
    v-if="isShow"
    :name="animationName"
    tag="div"
    :style="styles"
    class="bsc-cart-item-goods-belt-swiper"
  >
    <template v-for="(belt, i) in belts">
      <div
        v-if="belt === currentItem"
        :key="i"
        class="bsc-cart-item-goods-belt-swiper__item"
        :style="getBeltStyle(belt)"
      >
        {{ belt.beltText }}
      </div>
    </template>
  </TransitionGroup>
</template>

<script name="GoodsBeltSwiper" setup lang="ts">
import { ref, computed } from 'vue'
import { useCarousel } from '../../hooks/useCarousel'

/**
 * 商品行腰带组件
 */

// props
interface Belt {
  /**
   * 腰带文字
   */
  beltText: string
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 字体颜色
   */
  fontColor: string
}
interface GoodsBeltSwiperProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask?: boolean
  /**
   * 腰带列表
   */
  belts: Belt[]
}

const props = withDefaults(defineProps<GoodsBeltSwiperProps>(), {
  isShow: false,
  isShowMask: false,
  belts: () => [],
})

const belts = ref(props.belts)

const { currentItem, animationStyle, animationName } = useCarousel({
  list: belts,
  showTime: 2000,
  switchTime: 250,
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
    ...animationStyle,
  }
})

const getBeltStyle = (belt: Belt) => {
  return {
    '--font-color': belt.fontColor,
    '--bg-color': belt.bgColor,
  }
}

</script>

<style lang="less">
@height: 18/37.5rem;
.bsc-cart-item-goods-belt-swiper {
  position: relative;
  width: 100%;
  height: @height;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  opacity: var(--mask-opacity);
  &__item {
    padding: 0 4/37.5rem;
    width: 100%;
    height: @height;
    line-height: @height;
    color: var(--font-color);
    background-color: var(--bg-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
