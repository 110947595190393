import QuickShipTag from './quick-ship-tag.vue'
import QuickShipTagV2 from './quick-ship-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useQuickShipTag = createHelper('QuickShipTag', getProps)

export {
  QuickShipTag,
  QuickShipTagV2,
  useQuickShipTag,
}
