<template>
  <span
    v-if="isShow"
    class="bsc-cart-item-goods-copy"
    @click="handleClick"
  >
    <sui_icon_copy_12px_1
      size="18px"
      :color="color"
    />
  </span>
</template>

<script name="GoodsCopy" setup lang="ts">
import sui_icon_copy_12px_1 from '../../components/icon/sui_icon_copy_12px_1.vue'

/**
 * 商品行复制组件
 */

// events
const emit = defineEmits(['onClick'])

interface IProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 颜色
   */
  color?: string
}

// props
withDefaults(defineProps<IProps>(), {
  isShow: false,
})

// methods
const handleClick = e => {
  emit('onClick', e)
}
</script>

<style lang="less">
.bsc-cart-item-goods-copy {
  display: inline-block;
  width: 18/37.5rem;
  height: 18/37.5rem;
}
</style>


