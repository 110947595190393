import GoodsAddOnTip from './goods-add-on-tip.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsAddOnTip = createHelper('GoodsAddOnTip', getProps)

export {
  GoodsAddOnTip,
  useGoodsAddOnTip,
}
