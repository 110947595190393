import VoucherTag from './voucher-tag.vue'
import VoucherTagV2 from './voucher-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useVoucherTag = createHelper('VoucherTag', getProps)

export {
  VoucherTag,
  VoucherTagV2,
  useVoucherTag,
}
