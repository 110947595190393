<template>
  <span
    class="bsc_icon_info_12px_2"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.32839 6.00007L4.14641 9.18205L4.85352 9.88916L8.7426 6.00007L4.85352 2.11099L4.14641 2.81809L7.32839 6.00007Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_info_12px_2" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '12px',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
})) as any
</script>

<style lang="less">
.bsc_icon_info_12px_2 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  transform: rotate(0) /* rtl: rotate(-180deg)*/;
}
</style>
