<template>
  <span
    class="bsc_icon_add_16px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75 4V7.2495L12 7.25V8.75H8.75V12H7.25V8.75H4V7.25H7.25V4H8.75Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_add_16px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: 'inhert',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_add_16px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 14px);
  color: var(--color, '#000');
}
</style>
