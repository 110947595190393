<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-add-more__wrap"
    @click="handleClick"
  >
    <span class="bsc-cart-item-add-more__text">
      {{ text }}
    </span>
  </div>
</template>

<script name="AddMore" setup lang="ts">
import { computed } from 'vue'

/**
 * 商品行加车组件
 */

// events
const emit = defineEmits(['onClick'])

interface AddMoreProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 文案
   */
  text?: string
  /**
   * 背景色
   */
  bgColor?: string
  /**
   * 边框色
   */
  borderColor?: string
}

// props
const props = withDefaults(defineProps<AddMoreProps>(), {
  isShow: false,
  text: '',
  bgColor: '#F2F2F2',
  borderColor: '#E5E5E5',
})

// computed
const styles = computed(() => {
  return {
    '--bg-color': props.bgColor,
    '--border-color': props.borderColor,
  }
})

// methods
const handleClick = e => {
  emit('onClick', e)
}
</script>

<style lang="less">
.bsc-cart-item-add-more {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20/37.5rem;
    padding: 4/37.5rem 6/37.5rem;
    border: 1px solid var(--border-color);
    background-color: var(--bg-color);
    border-radius: 10/37.5rem;
    width: fit-content;
  }
  &__text {
    font-size: 12px;
    font-weight: 700;
    color: #222;
  }
}
</style>
