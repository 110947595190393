import type { GoodsAddOnTip } from '../../../../types/mobile/index'

/**
 * 凑单提示组件 GoodsAddOnTip
 * GoodsAddOnTip 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): GoodsAddOnTip.Props {
  const additionGoodsView = data.value.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView
  return {
    isShow: !!additionGoodsView?.additionGoodsBottomTip,
    text: additionGoodsView?.additionGoodsBottomTip || '',
    isAddOn: !!+additionGoodsView?.isJumpAdditemPop,
    isClick: true,
  }
}
