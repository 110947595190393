<template>
  <span
    class="bsc_icon_more_down_18px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5962 6.04297L15.0104 7.45718L9.00003 13.4676L2.98962 7.45718L4.40384 6.04297L9.00003 10.6391L13.5962 6.04297Z"
        fill="#1C1C1C"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_more_down_18px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '18px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_more_down_18px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 18px);
  color: var(--color, '#000');
}
</style>
