import type { AddCart } from '../../../../types/mobile/index.ts'

/**
 * AddCart 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps(): AddCart.Props {
  return {
    isShow: true,
  }
}
