<template>
  <span
    v-if="isShow"
    :class="['bsc-cart-item-goods-qty-text', { 'bsc-cart-item-goods-qty-text_mask': isShowMask }]"
    @click="onClick"
  >
    <sui_icon_save_movetowishlist_20px />
  </span>
</template>

<script name="GoodsAddWish" setup lang="ts">
import sui_icon_save_movetowishlist_20px from '../../components/icon/sui_icon_save_movetowishlist_20px.vue'

/**
 * 商品行添加心愿组件
 */

// events
const emit = defineEmits(['onClick', 'onReport'])

// props
interface IGoodsAddWishProps {
  isShow: boolean
  isShowMask: boolean
  isNewUserGoods: boolean
}
const props = defineProps<IGoodsAddWishProps>()

const onClick = () => {
  emit('onClick', { isNewUserGoods: props.isNewUserGoods })
  emit('onReport', { isNewUserGoods: props.isNewUserGoods })
}
</script>

<style lang="less">
.bsc-cart-item-goods-qty-text {
  width: 18/37.5rem;
  height: 18/37.5rem;
  &.bsc-cart-item-goods-qty-text_mask {
    opacity: 0.3;
  }
}
</style>
