import type { GoodsCopy } from '../../../../types/mobile/index.ts'

/**
 * GoodsCopy 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): GoodsCopy.Props {
  return {
    isShow: data.value.isInvalid === '1',
    color: '#999',
  }
}
