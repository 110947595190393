import type { GoodsRepickButton } from '../../../../types/laptop/index.ts'

/**
 * 重选按钮组件 GoodsRepickButton
 * GoodsRepickButton 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsRepickButton.Props {
  const additionGoodsView = data.value.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView
  return {
    isShow: !!(additionGoodsView?.rePickIcon || additionGoodsView?.rePickBtnText),
    icon: additionGoodsView?.rePickIcon || '',
    text: additionGoodsView?.rePickBtnText || '',
  }
}
