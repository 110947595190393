<template>
  <span
    class="bsc-cart-item-goods-qty-text__wrap"
    :style="styles"
  >
    {{ text }}
  </span>
</template>

<script name="GoodsQtyText" setup lang="ts">
import { computed } from 'vue'

/**
 * 商品行商品数量组件
 */

interface GoodsQtyTextProps {
  /**
   * 按钮文案
   */
  text?: string
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
}
// props
const props = withDefaults(defineProps<GoodsQtyTextProps>(), {
  text: '',
  isShowMask: false,
  isClick: true,
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})
</script>

<style lang="less">
.bsc-cart-item-goods-qty-text__wrap {
  font-size: 12px;
  line-height: 1;
  color: #222;
  opacity: var(--mask-opacity);
}
</style>
