<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-goods-repick-button"
    :style="styles"
    @click="handleClick"
  >
    <img
      v-if="icon"
      :src="icon"
      alt=""
      class="bsc-cart-item-goods-repick-button__icon"
    />
    <span
      v-show="showText"
      ref="goodsRepickButtonTextRef"
      class="bsc-cart-item-goods-repick-button__text"
    >{{ text }}</span>
  </div>
</template>

<script name="GoodsRepickButton" setup lang="ts">
import { ref, onMounted, nextTick, computed } from 'vue'
/**
 * 商品重选按钮组件
 */

// events
const emit = defineEmits(['onClick'])

// props
interface IGoodsRepickButtonProps {
  isShow: boolean
  icon: string
  text: string
  maxWidth?: string
}
const props = withDefaults(defineProps<IGoodsRepickButtonProps>(), {
  isShow: false,
  icon: '',
  text: '',
  maxWidth: '1.89334rem',
})

const styles = computed(() => {
  return {
    '--max-width': props.maxWidth,
  }
})

const showText = ref(true)
const goodsRepickButtonTextRef = ref<HTMLElement | null>(null)
const updateShowText = () => {
  nextTick(() => {
    if (goodsRepickButtonTextRef.value) {
      showText.value = goodsRepickButtonTextRef.value.scrollWidth <= goodsRepickButtonTextRef.value.clientWidth
    }
  })
}

const handleClick = () => {
  emit('onClick')
}

onMounted(() => {
  updateShowText()
})
</script>

<style lang="less">
.bsc-cart-item-goods-repick-button {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  max-width: var(--max-width);
  height: 36/75rem;
  border: solid 1/75rem #BBBBBB;
  border-radius: 24/75rem;
  background: #fff;
  color: #000;
  font-size: 12px;
  padding: 4/75rem 16/75rem;
  overflow: hidden;
  .bsc-cart-item-goods-repick-button__icon {
    width: 24/75rem;
    height: 24/75rem;
  }
  .bsc-cart-item-goods-repick-button__text {
    margin-left: 8/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
