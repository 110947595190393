<template>
  <span
    class="bsc_icon_delete_18px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em;"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6846 1.7998H6.31535L5.19037 4.27478H1.80005V5.62478H16.2V4.27478H12.8096L11.6846 1.7998ZM11.3267 4.27478L10.8153 3.1498H7.18425L6.6731 4.27478H11.3267Z"
        fill="#1C1C1C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.725 5.62482V14.8498H13.275V5.62482H14.625V16.1998H3.375V5.62482H4.725Z"
        fill="#1C1C1C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.1 8.32483V12.3748H6.75V8.32483H8.1Z"
        fill="#1C1C1C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.475 12.3748V8.32483H10.125V12.3748H11.475Z"
        fill="#1C1C1C" />
    </svg>
  </span>
</template>

<script name="sui_icon_delete_18px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '18px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_delete_18px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
