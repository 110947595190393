import FindNewsText from './find-news-text.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useFindNewsText = createHelper('FindNewsText', getProps)

export {
  FindNewsText,
  useFindNewsText,
}
