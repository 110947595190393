<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<template>
  <div
    v-if="isShow"
    :class="classnames"
    :style="style"
    @click="handleClick"
  >
    <div class="bsc-cart-item-promotion-tag-v2__text">
      {{ text }}
    </div>
    <SPopover
      v-if="tip"
      :content="tip"
      :prop-style="propStyle"
    >
      <sui_icon_info_12px_1
        slot="reference"
        class="bsc-cart-item-promotion-tag-v2__icon-tip"
        size="10px"
      />
    </SPopover>
    <ClientOnly>
      <template v-if="showCountdown">
        <div class="bsc-cart-item-promotion-tag-v2__line"></div>
        <template v-if="displayDateOrTime && dateFormat">
          <div class="bsc-cart-item-promotion-tag-v2__date">
            {{ dateFormat }}
          </div>
        </template>
        <template v-else>
          <CartCountdown
            v-if="timestamp"
            class="bsc-cart-item-promotion-tag-v2__count-down"
            v-bind="cartCountDownProps"
          />
        </template>
      </template>
    </ClientOnly>
    <sui_icon_info_12px_2
      v-if="isClick"
      size="12px"
      class="bsc-cart-item-promotion-tag-v2__icon-more"
    />
  </div>
</template>

<script name="PromotionTag" lang="ts" setup>
import { computed, watch } from 'vue'
import ClientOnly from 'vue-client-only'
import SPopover from '@shein/sui-mobile/lib/popover'
import sui_icon_info_12px_1 from '../../../components/icon/sui_icon_info_12px_1.vue'
import sui_icon_info_12px_2 from '../../../components/icon/sui_icon_info_12px_2.vue'

import { hexToRgb, timeTransformer } from '@shein/common-function'

import CartCountdown from '../../../components/cart-countdown.vue'
import { useInjectRegisterExpose } from '../../../hooks/useExpose'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 提示文案
   */
  tip: string
  /**
   * 倒计时时间戳
   */
  timestamp?: number | null
  /**
   * endInTip
   */
  endInTip: string
  /**
   * 是否展示年月日倒计时
   */
  displayDateOrTime: boolean
  /**
   * 标签类型
   */
  type: string
  /**
   * 原数据
   */
  data: any
  /**
   * 气泡提示样式
   */
  propStyle?: any
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 最大宽度，需要单位
   */
  maxWidth?: string
  /**
   * SiteUID type sDateMap
   */
  // eslint-disable-next-line vue/prop-name-casing
  MESSAGE_DATE: object
}

// props
const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  isClick: false,
  type: '',
  text: '',
  tip: '',
  hideCountdown: false,
  timestamp: null,
  endInTip: '',
  displayDateOrTime: false,
  data: null,
  propStyle() {
    return {
      width: 'max-content',
      maxWidth: '240px',
      zIndex: Number.MAX_SAFE_INTEGER,
    }
  },
  bgColor: '',
  textColor: '',
  maxWidth: '100%',
  // MESSAGE_DATE: null,
})

// hooks
const registerExpose = useInjectRegisterExpose()

const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

const classnames = computed(() => {
  const cls = [
    'bsc-cart-item-promotion-tag-v2',
    {
      'has-count-down': showCountdown.value,
    },
  ]
  if (props.type) cls.push(`bsc-cart-item-promotion-tag-v2__${props.type}`)
  return cls
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--text-color': props.textColor,
  '--bg-color': props.bgColor,
  '--rgba-bg-color': hexToRgb({ hex: props.bgColor, opa: props.type === 'flash' ? 0.3 : 0.5 }),
  '--rgba-line-color': hexToRgb({ hex: props.textColor, opa: 0.4 }),
  maxWidth: props.maxWidth,
}))

const dateFormat = computed(() => {
  if (typeof window === 'undefined') return ''

  if (!props.timestamp || !props.endInTip) return ''

  const text = timeTransformer({
    time: props.timestamp * 1000,
    sDateMap: props.MESSAGE_DATE,
  })
  return `${props.endInTip} ${text}`
})

const showCountdown = computed(() => {
  if (props.hideCountdown || !props.timestamp) return false

  if (props.displayDateOrTime) return !!dateFormat.value

  return true
})

const cartCountDownProps = computed(() => ({
  timestamp: +(props.timestamp ?? 0),
  hideOnZero: true,
  reloadOnZero: true,
  fontColor: props.textColor,
  colonColor: props.textColor,
  fontBgColor: 'transparent',
  fontWeight: '600',
  colonSpace: '1px',
}))

const handleClick = () => {
  emit('onReport', props.data)
  if (props.isClick) {
    emit('onClick', props.data)
  }
}

const handleExpose = () => {
  watch(() => props.isShow, (n) => {
    if (n) {
      const callback = () => {
        emit('onExpose', props.data)
      }
      registerExpose('PromotionTag', callback)
    }
  }, {
    immediate: true,
  })
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-promotion-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 14px;
  line-height: 1;
  font-size: 10px;
  vertical-align: middle;
  opacity: var(--mask-opacity);
  background: var(--bg-color);

  &.has-count-down {
    background: linear-gradient(90deg, var(--bg-color) 0%, var(--rgba-bg-color) 100%);
  }

  &.bsc-cart-item-promotion-tag-v2__club-gift {
    &.has-count-down {
      background: linear-gradient(90deg, #FFECE9 0%, rgba(#FFECE9, .3) 100%);
    }

    .bsc-cart-item-promotion-tag-v2__line {
      border-left-color: var(--rgba-line-color);
    }

    .bsc-cart-item-promotion-tag-v2__icon-more {
      color: var(--text-color);
    }
  }

  &.bsc-cart-item-promotion-tag-v2__coupon {
    position: relative;
    color: var(--text-color);
    background: @sui_color_white;
    border: 1px solid var(--text-color);
    border-radius: 1px;
    overflow: hidden;
    mask-image: radial-gradient(circle at 0px 50%, transparent 3px, #fff 3px);

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: var(--text-color);
      border-radius: 50%;
      top: 50%;
      /* rtl:begin:ignore */
      margin-top: -4px;
      left: -5px;
    }
  }

  &__text {
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--text-color);
  }

  &__icon-tip {
    margin-left: 2px;
  }

  &__line {
    flex-shrink: 0;
    margin-left: 6px;
    margin-right: 4px;
    height: 10px;
    border-left: 1px solid var(--text-color);
    opacity: 0.4;
  }

  &__count-down {
    flex-shrink: 0;
  }

  &__date {
    flex-shrink: 0;
    color: var(--text-color);
  }

  &__icon-more {
    flex-shrink: 0;
    color: var(--text-color);
  }
}
</style>
