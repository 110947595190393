<template>
  <span
    class="bsc_icon_freeshipping_flat_24px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em;"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="24"
        height="24"
        fill="white"
        fill-opacity="0.01" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4085 6.92008V4H3.01038V8.64506V14.2934V18.4515H4.76122C5.23999 16.7751 6.78347 15.5479 8.61359 15.5479C10.4437 15.5479 11.9872 16.7751 12.4659 18.4515H13.6169C14.0957 16.7751 15.6392 15.5479 17.4693 15.5479C19.2995 15.5479 20.843 16.7752 21.3217 18.4517H22.5698V8.60743L17.4085 6.92008ZM17.4085 12.4516V9.02451L20.5692 10.0576V12.4516H17.4085Z"
        fill="currentColor" />
      <circle
        cx="17.5169"
        cy="19.554"
        r="2"
        fill="currentColor" />
      <circle
        cx="8.61359"
        cy="19.554"
        r="2"
        fill="currentColor" />
    </svg>
  </span>
</template>

<script name="sui_icon_freeshipping_flat_24px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '24px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_freeshipping_flat_24px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 14px);
  color: var(--color, '#000');
}
</style>
