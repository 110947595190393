import { hexToRgb } from '@shein/common-function'
import { getTimestampFormProductTags } from '../../utils/common'

import { EstimatedPrice } from '../../../../types/mobile/index.ts'

/**
 * EstimatedPrice 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): EstimatedPrice.Props {
  const getHtmlPrice = (amountWithSymbol, priceShowStyle) => amountWithSymbol?.replace(priceShowStyle, `<em>${priceShowStyle}</em>`)

  const apb = data.value.aggregateProductBusiness || {}
  const { estimatedData = {} } = apb

  // 新人专享商品
  const isNewcomerItem = apb.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = apb.isPresent === '1' || apb.isPayMemberGift === '1'
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  const isShowMask = (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem

  const text = estimatedData.estimatedTip
  const price = estimatedData.estimatedPrice ? getHtmlPrice(estimatedData.estimatedPrice.amountWithSymbol, estimatedData.estimatedPrice.priceShowStyle) : ''

  const estimatedPricePopUp = apb.estimatedPricePopUp && JSON.parse(JSON.stringify(apb.estimatedPricePopUp))
  if (estimatedPricePopUp?.subInfo?.items) estimatedPricePopUp.subInfo.items = estimatedPricePopUp?.subInfo.items.filter(item => item.benefitType !== 'estimatedAmount')

  const timestamp = +(estimatedData.estimatedPriceEndTimeStamp ?? 0)

  let isShowEndTimestamp = !!timestamp
  if (isShowEndTimestamp) {
    isShowEndTimestamp = !getTimestampFormProductTags(apb.productTags)
  }

  const textColor = estimatedData.textColor
  const lineColor = textColor && hexToRgb({ hex: textColor, opa: 0.4 })

  return {
    isShow: !!estimatedData.style && !!text,
    isShowMask,
    isClick: !isShowMask && !!estimatedPricePopUp,
    text,
    price,
    timestamp,
    hideCountdown: !isShowEndTimestamp,
    estimatedPricePopUp,

    textColor,
    lineColor,
    bgColor: 'linear-gradient(90deg, #FFECE9 0%, #FFFAF9 100%)',

    isChecked: data.value.is_checked === '1',
    estimatedPrice: apb?.priceData?.estimatedPrice?.price,
  }
}

