import type { GoodsTitleIcon } from '../../../../types/mobile/index.ts'

/**
 * GoodsTitleIcon 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsTitleIcon.Props {
  return {
    isShow: !!data.value.aggregateProductBusiness?.productImgLabel && !!data.value.aggregateProductBusiness?.productImgLabel?.titleImg,
    imgUrl: data.value.aggregateProductBusiness?.productImgLabel?.titleImg ?? '',
  }
}
