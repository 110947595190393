import type { GoodsQtyFlag } from '../../../../types/mobile/index.ts'

/**
 * GoodsQtyFlag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsQtyFlag.Props {
  return {
    text: `x${data.value.quantity}`,
  }
}
