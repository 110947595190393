import { isCustomizationProduct } from '../../utils/common'
import type { GoodsSaleAttr } from '../../../../types/mobile/index.ts'

/**
 * 商品销售属性组件 GoodsSaleAttr
 * GoodsSaleAttr 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsSaleAttr.Props {
  const isCustomGoods = isCustomizationProduct(data.value)
  // 是否赠品/加价购附属品
  const isAdditionGoods = data.value.aggregateProductBusiness?.isAdditionGoods == 1
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  // 是否付费会员
  const isPayMemberGift = data.value?.isPayMemberGift === '1'
  // 付费会员赠品重选按钮
  const payMemberGiftView = data.value.aggregateProductBusiness?.cartRowViewControl?.payMemberGiftView
  /**
   * 是否展示重新选择按钮
   * 商品售罄：
   *    1. 有效商品（当前尺码售罄，其他尺码有货）&& 非赠品/加价购附属品 && 非新人商品
   *    2. 付费会员赠品 && 付费会员权益存在
   */
  const showReselect = data.value.aggregateProductBusiness?.changeGoodInfo?.isOtherAttr === '1' || !!payMemberGiftView

  // 颜色图
  const colorImage = data.value.product?.color_image || ''
  // 尺码
  const goodsAttr = data.value.aggregateProductBusiness?.goodsAttr || ''

  // 是否展示尺码按钮
  const showAttr = data.value.isInvalid != 1 && !!(colorImage || goodsAttr)

  // 是否显示蒙层
  let isShowMask = false
  // 是否可点击
  let isClick = true
  if (isCustomGoods || isNewUserGoods || ((isAdditionGoods || isPayMemberGift) && data.value.is_checked === '0')) {
    isShowMask = true
  }
  if (isNewUserGoods || isAdditionGoods || isPayMemberGift) {
    isClick = false
  }

  return {
    isShow: showReselect || showAttr,
    mode: showReselect ? 'reselect' : 'attr',
    isShowMask,
    isClick,
    hideArrow: isAdditionGoods,
    reselectText: data.value.aggregateProductBusiness?.changeGoodInfo?.buttonTip || payMemberGiftView?.reSelectBtnText || language.value.SHEIN_KEY_PWA_16112 || '',
    color: showReselect ? '#222' : '#666',
    fontWeight: showReselect ? 'bold' : 'normal',
    colorImage,
    goodsAttr,
    isCustomGoods,
    capsuleBgColor: '#F2F2F2',
    capsuleBorderColor: '#E5E5E5',
  }
}
