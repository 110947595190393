<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-change-mall__wrap"
    @click="handleClick"
  >
    <span class="bsc-cart-item-change-mall__text">
      {{ btnText }}
    </span>
  </div>
</template>

<script name="ChangeMall" setup lang="ts">
import { computed, watch } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'

/**
 * 商品行切换MALL组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

interface ChangeMallProps {
  /**
   * 是否展示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否能点击
   */
  isClick: boolean
  /**
   *  按钮文案
   */
  btnText: string
}

// props
const props = withDefaults(defineProps<ChangeMallProps>(), {
  isShow: false,
  isShowMask: false,
  isClick: true,
  btnText: '',
})

// data
// computed
const styles = computed(() => {
  return {
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

// events
const handleClick = () => {
  emit('onReport')
  if (props.isClick) {
    emit('onClick')
  }
}
const handleExpose = () => {
  watch(
    () => props.isShow,
    n => {
      if (n) {
        const callback = () => {
          emit('onExpose')
        }
        registerExpose('ChangeMall', callback)
      }
    },
    {
      immediate: true,
    },
  )
}
handleExpose()
</script>

<style lang="less">
.bsc-cart-item-change-mall {
  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20/37.5rem;
    padding: 4/37.5rem 10/37.5rem;
    border: 0.5px solid #000;
    background-color: #ffffff;
    border-radius: 16/37.5rem;
    cursor: pointer;
    opacity: var(--mask-opacity);
  }

  &__text {
    font-size: 12px;
    font-weight: 700;
    color: #222;
  }
}
</style>
