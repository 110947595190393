import DiscountLimitTag from './discount-limit-tag.vue'
import DiscountLimitTagV2 from './discount-limit-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useDiscountLimitTag = createHelper('DiscountLimitTag', getProps)

export {
  DiscountLimitTag,
  DiscountLimitTagV2,
  useDiscountLimitTag,
}
