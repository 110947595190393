import type { GoodsNegativeInfo } from '../../../../types/mobile/index.ts'

/**
 * 负向信息组件 GoodsNegativeInfo
 * GoodsNegativeInfo 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsNegativeInfo.Props {
  const negativeInfo = data.value.aggregateProductBusiness?.negativeInfo || {}
  const negativeInfos: GoodsNegativeInfo.negativeItem[] = []
  if (negativeInfo.showNotPoints === '1' && negativeInfo.notPointsText) {
    negativeInfos.push({
      text: negativeInfo.notPointsText || '',
    })
  }
  if (negativeInfo.showNotCoupon === '1' && negativeInfo.notCouponText) {
    negativeInfos.push({
      text: negativeInfo.notCouponText || '',
    })
  }
  if (negativeInfo.showNotReturn === '1' && negativeInfo.notReturnText) {
    negativeInfos.push({
      text: negativeInfo.notReturnText || '',
    })
  }
  return {
    isShow: negativeInfos.length > 0,
    negativeInfos,
    trigger: 'Mounted',
    textColor: '#959595',
    maxWidth: '100%',
  }
}
