<template>
  <div
    v-if="isShow"
    class="bsc-cart-item-free-shipping-tag-v2"
    :style="style"
  >
    <div class="bsc-cart-item-free-shipping-tag-v2__text">
      {{ text }}
    </div>
  </div>
</template>

<script name="FreeShippingTag" lang="ts" setup>
import { computed } from 'vue'

interface IProps {
  /**
   * 是否显示
   */
  isShow: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 标签文案
   */
  text: string
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 文字颜色
   */
  textColor: string
  /**
   * 最大宽度，单位rem
   */
  maxWidth?: string
}

// props
const props = withDefaults(defineProps<IProps>(), {
  isShow: false,
  isShowMask: false,
  text: '',
  bgColor: '',
  textColor: '',
  maxWidth: '100%',
})

const style = computed(() => ({
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
  '--bg-color': props.bgColor,
  '--text-color': props.textColor,
  maxWidth: props.maxWidth,
}))
</script>

<style lang="less">
.bsc-cart-item-free-shipping-tag-v2 {
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  height: 14px;
  line-height: 1;
  font-size: 10px;
  color: var(--text-color);
  background: var(--bg-color);
  vertical-align: middle;
  opacity: var(--mask-opacity);

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
