<template>
  <div
    v-if="isShow"
    :class="[
      'bsc-cart-item-goods-sale-attr',
      {
        'bsc-cart-item-goods-sale-attr_mask': isShowMask,
        'bsc-cart-item-goods-sale-attr_capsule': type === 'capsule',
      },
    ]"
    :style="styles"
    @click="handleClick"
  >
    <div
      v-if="mode === 'attr' && (colorImage || goodsAttr)"
      class="bsc-cart-item-goods-sale-attr__content">
      <img
        v-if="colorImage"
        :src="colorImage"
        alt=""
        class="bsc-cart-item-goods-sale-attr__img"
      />
      <span
        v-if="goodsAttr"
        class="bsc-cart-item-goods-sale-attr__text">
        {{ goodsAttr }}
      </span>
    </div>
    <span v-if="mode === 'reselect'">{{ reselectText }}</span>
    <sui_icon_more_down_12px
      v-if="!hideArrow && type === 'capsule'"
      class="bsc-cart-item-goods-sale-attr__icon"
    />
  </div>
</template>

<script name="GoodsSaleAttr" setup lang="ts">
import { computed, withDefaults } from 'vue'
import sui_icon_more_down_12px from '../../components/icon/sui_icon_more_down_12px.vue'

/**
 * 商品销售属性组件
 */

// events
const emit = defineEmits(['onClick'])

// props
interface IGoodsSaleAttrProps {
  /**
   * 是否显示
   */
    isShow: boolean
  /**
   * 展示的形式
   */
  mode: string
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 是否可点击
   */
  isClick: boolean
  /**
   * 是否隐藏箭头
   */
  hideArrow: boolean
  /**
   * 重选按钮文案
   */
  reselectText: string
  /**
   * 颜色
   */
  color: string
  /**
   * 字体粗细
   */
  fontWeight: string
  /**
   * 色块图
   */
  colorImage: string
  /**
   * 商品属性
   */
  goodsAttr: string
  /**
   * 类型
   */
  type?: string
  /**
   * 字体大小，单位px
   */
  size?: string
  /**
   * 最大宽度，单位rem
   */
  maxWidth?: string
  /**
   * 高度，单位rem
   */
  height?: string
  /**
   * 内边距，单位rem
   */
  padding?: string
  /**
   * 是否定制商品
   */
  isCustomGoods: boolean
  /**
   *  胶囊的背景色
   */
  capsuleBgColor: string
  /**
   * 胶囊的边框色
   */
  capsuleBorderColor: string
}
const props = withDefaults(defineProps<IGoodsSaleAttrProps>(), {
  // 类型：capsule胶囊型、text文字型
  type: 'capsule',
  size: '0.32rem',
  maxWidth: '100%',
  height: '0.534rem',
  padding: '0 0.16rem',
})

// computed
const styles = computed(() => {
  return {
    '--color': props.color,
    '--size': props.size,
    '--font-weight': props.fontWeight,
    '--max-width': props.maxWidth,
    '--height': props.height,
    '--padding': props.padding,
    '--capsule-bg-color': props.capsuleBgColor,
    '--capsule-border-color': props.capsuleBorderColor,
  }
})

// events
const handleClick = () => {
  if (props.isClick) {
    emit('onClick', { event: props.mode, isCustomGoods: props.isCustomGoods })
  }
}
</script>

<style lang="less" scoped>
.bsc-cart-item-goods-sale-attr {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  max-width: var(--max-width);
  font-size: var(--size);
  color: var(--color);
  font-weight: var(--font-weight);
  height: var(--height);
  line-height: var(--height);
  cursor: default;
  &.bsc-cart-item-goods-sale-attr_mask {
    opacity: 0.3;
  }
  &.bsc-cart-item-goods-sale-attr_capsule {
    padding: var(--padding);
    border-radius: var(--height);
    background-color: var(--capsule-bg-color);
    border: 1px solid var(--capsule-border-color);
  }
  .bsc-cart-item-goods-sale-attr__content {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .bsc-cart-item-goods-sale-attr__icon {
    margin-left: 4/37.5rem;
  }
  .bsc-cart-item-goods-sale-attr__img {
    box-sizing: border-box;
    margin-right: 4/37.5rem;
    width: 12/37.5rem;
    height: 12/37.5rem;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    object-fit: contain;
  }
  .bsc-cart-item-goods-sale-attr__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
