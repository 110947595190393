import { isCustomizationProduct } from '../../utils/common'
import type { GoodsCustomContent } from '../../../../types/mobile/index.ts'

/**
 * GoodsCustomContent 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language }): GoodsCustomContent.Props {
  return {
    isShow: isCustomizationProduct(data.value) && data.value.isInvalid != 1,
    title: language.value.SHEIN_KEY_PWA_26444,
    data: data.value.aggregateProductBusiness?.customizationPopInfo || {},
  }
}
