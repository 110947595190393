import EvoluTag from './evolu-tag.vue'
import EvoluTagV2 from './evolu-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useEvoluTag = createHelper('EvoluTag', getProps)

export {
  EvoluTag,
  EvoluTagV2,
  useEvoluTag,
}
