import type { SharePromotionTag } from '../../../../types/mobile/index.ts'

/**
 * SharePromotionTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): SharePromotionTag.Props {
  const getType = (info) =>{
    if (info.type === 'promotion') {
      const tagData = info && info.data?.[info.type]
      if (tagData.typeId === '10' && ['1', '4', '5'].includes(tagData.flashType)) return 'flash'
      if (tagData.typeId === '32') return 'coupon'
      return 'normal'
    }
    return 'normal'
  }

  const tags = data.value?.productTags
    ?.filter(item => item.type == 'promotion')
    .map(item => ({
      text: item.view.text,
      textColor: item.view.textColor,
      bgColor: item.view.bgColor,
      type: getType(item),
    }))
    .slice(0, 2) ?? []

  return {
    isShow: !!tags.length,
    tags: tags,
  }
}
