<template>
  <div
    v-if="isShow"
    :class="classes"
    @click="handleClick"
  >
    <span>{{ text }}</span>
    <sui_icon_more_down_18px
      size="12px"
      class="bsc-cart-item-find-news-text__icon"
    />
  </div>
</template>

<script name="FindNewsText" setup lang="ts">
import { computed } from 'vue'
import sui_icon_more_down_18px from '../../components/icon/sui_icon_more_down_18px.vue'

// events
const emit = defineEmits(['onClick'])

// props
interface IProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 展示文案
   */
  text: string
  /**
   * 是否打开
   */
  isOpen?: boolean
}
const props = withDefaults(defineProps<IProps>(), {
  isShow: true,
  text: 'Find News',
  isOpen: false,
})

// events
const handleClick = () => {
  emit('onClick')
}

const classes = computed(() => {
  return [
    'bsc-cart-item-find-news-text',
    {
      'bsc-cart-item-find-news-text--open': props.isOpen,
    },
  ]
})
</script>

<style lang="less">
.bsc-cart-item-find-news-text {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20/37.5rem;
  padding: 4/37.5rem 6/37.5rem;
  border: 1px solid transparent;
  background-color: #f2f2f2;
  border-radius: 10/37.5rem;
  font-size: 12px;
  font-weight: 700;
  color: #222;
  &__icon {
    margin-left: 2/37.5rem;
  }
  &--open {
    .bsc-cart-item-find-news-text__icon {
      transform: rotate(180deg);
    }
  }
}
</style>
