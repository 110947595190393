import type { QuickShipTag } from '../../../../../types/mobile/index.ts'

/**
 * QuickShipTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): QuickShipTag.Props {
  const info = data.value.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'quickShip') || null

  // 新人专享商品
  const isNewcomerItem = data.value.aggregateProductBusiness?.showNewUsersBonus === '1'
  // 是否赠品
  const isGiftItem = (data.value.aggregateProductBusiness?.isPresent === '1' || data.value?.isPayMemberGift === '1')
  // 是否选择
  const isNotChecked = data.value.is_checked === '0'
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: !!info,
    isShowMask: (isGiftItem && isNotChecked) || isInvalidItem || isNewcomerItem,
    text: info?.view?.text || '',
    isShowTimeText: !!info?.view?.suffixText,
    isShowNewStyle: info?.style == '1',
    timeText: info?.view?.suffixText || '',
    prefixIcon: info?.view?.prefixIcon || '',
    bgColor: info?.view?.bgColor,
    textColor: info?.view?.textColor,
    maxWidth: '100%',
  }
}
