import type { GoodsBelt } from '../../../../types/mobile/index.ts'
import { hexToRgb } from '@shein/common-function'

/**
 * GoodsBelt 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsBelt.Props {
  const bgColor = data.value.aggregateProductBusiness?.productImgBelt?.bgColor || '#000000'
  const bgColorAlpha = data.value.aggregateProductBusiness?.productImgBelt?.bgColorAlpha || '0.6'
  const fontColor = data.value.aggregateProductBusiness?.productImgBelt?.textColor || ''
  const beltText = data.value.aggregateProductBusiness?.productImgBelt?.text || ''
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
   // 是否赠品/加价购附属品
  const isAdditionGoods = (data.value.aggregateProductBusiness?.isAdditionGoods == 1 || data.value?.isPayMemberGift === '1') && data.value.is_checked == '0'
  // 是否失效商品
  const isInvalidGoods = data.value.isInvalid == '1'

  return {
    isShow: !!beltText,
    isShowMask: (isNewUserGoods || isAdditionGoods) && !isInvalidGoods,
    bgColor: hexToRgb({ hex: bgColor, opa: bgColorAlpha }),
    fontColor,
    beltText: beltText,
  }
}
