import GoodsPrice from './goods-price.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsPrice = createHelper('GoodsPrice', getProps)

export {
  GoodsPrice,
  useGoodsPrice,
}
