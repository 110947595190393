<template>
  <div
    :style="styles"
    :class="classes"
    @click="handleClick"
  >
    <div class="bsc-cart-item-goods-title__slot-left">
      <slot name="left"></slot>
    </div>
    <a
      :title="title"
      class="bsc-cart-item-goods-title__content"
      v-html="title"
    >
    </a>
  </div>
</template>

<script name="GoodsTitle" setup lang="ts">
import { computed } from 'vue'
import { useInjectRegisterExpose } from '../../hooks/useExpose'
import { onMountedOrActivated } from '../../hooks/onMountedOrActivated'

/**
 * 商品行标题组件
 */

// hooks
const registerExpose = useInjectRegisterExpose()

// events
const emit = defineEmits(['onClick', 'onReport', 'onExpose'])

interface GoodsTitleProps {
  /**
   * 标题
   */
  title?: string
  /**
   * 是否展示遮罩
   */
  isShowMask: boolean
  /**
   * 字体颜色
   */
  fontColor?: string
  /**
   * 标题大小
   */
  fontSize?: string
  /**
   * 是否可点击
   */
  isClick?: boolean
}
// props
const props = withDefaults(defineProps<GoodsTitleProps>(), {
  title: '',
  isShowMask: false,
  fontColor: '#222',
  fontSize: '12px',
  isClick: true,
})

// data
// computed
const styles = computed(() => {
  return {
    '--font-color': props.fontColor,
    '--font-size': props.fontSize,
    '--mask-opacity': props.isShowMask ? '0.3' : '1',
  }
})

const classes = computed(() => {
  return {
    'bsc-cart-item-goods-title__wrap': true,
    'bsc-cart-item-goods-title_isClick': props.isClick,
  }
})

// events
const handleClick = () => {
  emit('onReport')
  if (props.isClick) {
    emit('onClick')
  }
}
const handleExpose = () => {
  const callback = () => emit('onExpose')
  registerExpose('GoodsTitle', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
.bsc-cart-item-goods-title {
  &__wrap {
    display: flex;
    align-items: center;
    opacity: var(--mask-opacity);
    overflow: hidden;
  }
  &_isClick {
    .bsc-cart-item-goods-title__content {
      cursor: pointer;
    }
  }
  &__slot-left {
    flex-shrink: 0;
  }
  &__content {
    font-size: var(--font-size);
    line-height: 1;
    color: var(--font-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
  }
}
</style>
