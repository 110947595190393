import type { GoodsAddWish } from '../../../../types/mobile/index.ts'

/**
 * GoodsAddWish 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }): GoodsAddWish.Props {
  // 是否赠品/加价购附属品
  const isAdditionGoods = data.value.aggregateProductBusiness?.isAdditionGoods == '1'
  // 是否新人专享商品
  const isNewUserGoods = data.value.aggregateProductBusiness?.showNewUsersBonus == '1'
  // 失效商品显示到正常分组
  const isSwitchItem = data.value.aggregateProductBusiness?.cartItemStyleType === '2'
  // 是否付费会员赠品
  const isPayMemberGift = data.value?.isPayMemberGift === '1'
  // 是否附赠品(包含付费会员赠品)
  const isGiftItem = isAdditionGoods || isPayMemberGift
  // 失效商品
  const isInvalidItem = data.value.isInvalid === '1'

  return {
    isShow: !isGiftItem || (isInvalidItem && !isSwitchItem),
    isShowMask: isNewUserGoods,
    isNewUserGoods,
  }
}
