import GoodsAlmostEntry from './goods-almost-entry.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsAlmostEntry= createHelper('GoodsAlmostEntry', getProps)

export {
  GoodsAlmostEntry,
  useGoodsAlmostEntry,
}
