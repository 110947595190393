<!-- eslint-disable vue/no-deprecated-slot-attribute-->
<!-- eslint-disable vue/no-deprecated-v-on-native-modifier-->
<template>
  <SPopover
    v-model="showPop"
    :prop-style="propStyle"
    :placemen="placemen"
    trigger-type="user"
    show-close-icon
  >
    <span :style="tipsStyles">{{ content }}</span>
    <sui_icon_info_12px_1
      slot="reference"
      class="j-cart-price-popover-icon"
      @click.native="togglePopover"
    />
  </SPopover>
</template>

<script>
import SPopover from '@shein/sui-mobile/lib/popover'
import sui_icon_info_12px_1 from '../../../components/icon/sui_icon_info_12px_1.vue'

/**
 * @description 购物车价格提示
 */
export default {
  name: 'PricePopover',
  components: {
    SPopover,
    sui_icon_info_12px_1,
  },
  props: {
    // 气泡提示内容
    content: {
      type: String,
      default: '',
    },
    // popover提示样式
    propStyle: {
      type: Object,
      default: () => ({
        width: '6.4rem',
        zIndex: Number.MAX_SAFE_INTEGER,
      }),
    },
    // 气泡提示样式
    tipsStyles: {
      type: Object,
      default: () => ({
        'white-space': 'normal',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        overflow: 'hidden',
        'max-width': '100%',
        'word-break': 'break-all',
      }),
    },
    // 监听滚动元素
    listenTarget: {
      type: String,
      default: '',
    },
    // 遮挡气泡的元素
    gapTarget: {
      type: String,
      default: '',
    },
  },
  emits: ['toggle'],
  data() {
    return {
      showPop: false,
      placemen: 'bottom',
    }
  },
  mounted() {
    this.addListen()
  },
  beforeUnmount() {
    this.removeScroll()
  },
  methods: {
    addListen() {
      this.$watch('showPop', val => {
        if (val) {
          this.listenScroll()
          this.exposeInstance()
        } else {
          this.removeScroll()
        }
      })
    },
    togglePopover() {
      if (!this.showPop) {
        this.placemen = this.getPlacemenDirection()
      }
      this.showPop = !this.showPop
      this.$emit('toggle', this.showPop)
    },
    getElem(querySelector, defaultElem) {
      if (querySelector) {
        const target = document.querySelector(querySelector)
        if (target instanceof HTMLElement) {
          return target
        }
      }
      return defaultElem
    },
    getPlacemenDirection() {
      const { y: gapTop } = this.getElem(this.gapTarget)?.getBoundingClientRect?.() ?? { y: document.body.clientHeight }
      const { y: popoverIcon } = this.$el.querySelector('.j-cart-price-popover-icon')?.getBoundingClientRect() ?? {}
      const popoverContentHeight = 60
      if (gapTop - popoverIcon < popoverContentHeight) {
        return 'top'
      }
      return 'bottom'
    },
    listenScroll() {
      this.getElem(this.listenTarget, document)?.addEventListener?.('scroll', this.togglePopover)
    },
    removeScroll() {
      this.getElem(this.listenTarget, document)?.removeEventListener?.('scroll', this.togglePopover)
    },
    exposeInstance() {
      window.__cart_item_price_popover__ = this
    },
  },
}
</script>
