import GoodsDiscountFlag from './goods-discount-flag.vue'
import GoodsDiscountFlagV2 from './goods-discount-flag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useGoodsDiscountFlag = createHelper('GoodsDiscountFlag', getProps)

export {
  GoodsDiscountFlag,
  GoodsDiscountFlagV2,
  useGoodsDiscountFlag,
}
