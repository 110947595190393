<template>
  <span
    class="bsc_icon_save_movetowishlist_20px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.51949 4.12945C6.87579 2.59148 4.29633 2.6242 2.69245 4.22762C1.89229 5.02778 1.506 5.99303 1.50012 7H3.00039C3.01083 6.3642 3.26412 5.77733 3.753 5.28845L3.8447 5.20092C4.90141 4.23807 6.53903 4.26724 7.56053 5.28845L9.0001 6.72803L10.4398 5.2883L10.5315 5.20078C11.5881 4.23807 13.2257 4.26724 14.2472 5.28845C15.2784 6.31965 15.2614 7.78683 14.1281 9.22959C14.1052 9.25877 14.0813 9.28877 14.0564 9.31958L13.8953 9.51405L13.7113 9.72728L13.5052 9.95856C13.469 9.99858 13.4319 10.0393 13.394 10.0808L13.1559 10.3378L12.8975 10.6112L12.6195 10.9002L12.3225 11.2041L12.0072 11.5223L11.7618 11.7674L11.3407 12.1833L10.8953 12.6178C10.2939 13.2012 9.66476 13.7988 9.03547 14.3871L9.0001 14.42L8.49323 13.9445C7.86574 13.353 7.24529 12.7582 6.6595 12.1833L6.24596 11.7749L5.83115 11.3595L5.47859 11H3.41497L3.65991 11.2672C3.70795 11.3189 3.75678 11.3711 3.80633 11.4238L4.11181 11.7454L4.43148 12.0756L4.76218 12.4118L5.17844 12.8288L5.50203 13.1489L6.25588 13.8836L6.88034 14.4825L8.05988 15.5945L9.00005 16.4638L10.1001 15.4451L11.3243 14.2872L11.9581 13.6765L12.7141 12.9356L13.2379 12.4118L13.5686 12.0756C13.6229 12.02 13.6767 11.9646 13.73 11.9095L14.043 11.5833L14.3402 11.2672C14.3882 11.2155 14.4355 11.1643 14.4819 11.1137L14.7494 10.8176C14.963 10.5774 15.1518 10.3546 15.3077 10.1562C16.8494 8.19347 16.945 5.86491 15.3077 4.22762C13.6704 2.59079 11.0164 2.59079 9.37912 4.22762L9.00005 4.60669L8.62099 4.22762L8.51949 4.12945Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 7L8 10H1.5L0.5 8.5H5.5L4.5 7H6Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_save_movetowishlist_20px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '18px',
  },
  color: {
    type: String,
    default: '#000',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
  '--color': props.color,
})) as any
</script>

<style lang="less">
.bsc_icon_save_movetowishlist_20px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 12px);
  color: var(--color, '#000');
}
</style>
