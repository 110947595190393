import type { GoodsAlmostEntry } from '../../../../types/mobile/index.ts'

/**
 * GoodsAlmostEntry 组件 props
 * @param {Object} data - 商品行数据
 */
export function getProps({ data }): GoodsAlmostEntry.Props {
  return {
    isShow: !!data.value?.aggregateProductBusiness?.cartAlmostPopupEntryCartItem,
    isClick: true,
    imgUrl: data.value?.aggregateProductBusiness?.cartAlmostPopupEntryCartItem?.imgUrl,
    entryText: data.value?.aggregateProductBusiness?.cartAlmostPopupEntryCartItem?.almostPopupEntryText,
  }
}
