import SFSTag from './SFSTag.vue'
import SFSTagV2 from './SFSTagV2.vue'
import { getProps } from './helper.ts'
import { createHelper } from '../../../hooks/useHelper.ts'

const useSFSTag = createHelper('SFSTag', getProps)

export {
  SFSTag,
  SFSTagV2,
  useSFSTag,
}
