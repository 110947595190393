import MorePromotionTag from './more-promotion-tag.vue'
import MorePromotionTagV2 from './more-promotion-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useMorePromotionTag = createHelper('MorePromotionTag', getProps)

export {
  MorePromotionTag,
  MorePromotionTagV2,
  useMorePromotionTag,
}
