<template>
  <div
    class="bsc-cart-item-goods-negative-item"
    :class="[
      hasAction && 'bsc-cart-item-goods-negative-item__pointer'
    ]"
    @click="onClick"
  >
    <div class="bsc-cart-item-goods-negative-item__left-icon">
      <sui_icon_reported_12px_1
        class="bsc-cart-item-goods-negative-item__icon"
        is-responsive-name
        size="12px"
      />
    </div>
    <div class="bsc-cart-item-goods-negative-item__content">
      <TextEllipsis
        ref="refTextEllipsis"
        :expanded.sync="expanded"
        :has-action.sync="hasAction"
        :content="text"
        :max-rows="5"
        :trigger="trigger"
      />
    </div>
    <div
      class="bsc-cart-item-goods-negative-item__right-icon"
      :class="[
        hasAction && 'bsc-cart-item-goods-negative-item__has-action'
      ]"
    >
      <sui_icon_more_down_12px_1
        class="bsc-cart-item-goods-negative-item__icon"
        :class="[
          expanded && 'bsc-cart-item-goods-negative-item__icon-flip'
        ]"
        size="12px"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import sui_icon_more_down_12px_1 from '../../components/icon/sui_icon_more_down_12px_1.vue'
import sui_icon_reported_12px_1 from '../../components/icon/sui_icon_reported_12px_1.vue'
import TextEllipsis from '../../components/text-ellipsis.vue'

defineProps({
  text: {
    type: String,
    default: '',
  },
  trigger: {
    type: String,
    default: 'Mounted',
  },
})

const expanded = ref(false)
const hasAction = ref(false)
const refTextEllipsis = ref(null)
const onClick = () => {
  if (!hasAction.value) return
  refTextEllipsis.value?.toggle()
}
</script>

<style lang="less">
.bsc-cart-item-goods-negative-item {
  display: flex;
  max-width: var(--max-width);

  &__left-icon {
    align-self: flex-start;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &__right-icon {
    display: inline-flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    opacity: 0;
    .bsc-cart-item-goods-negative-item__icon {
      align-self: flex-end;
    }
  }
  &__icon {
    flex-shrink: 0;
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--icon-color) !important;
  }
  &__content {
    flex: 1;
    margin: 0 4px;
    min-width: 0;
    font-size: var(--text-size);
    color: var(--text-color);
  }
  &__icon-flip {
    transform: rotateZ(180deg);
  }
  &__has-action {
    /* stylelint-disable-next-line declaration-no-important */
    opacity: 1 !important;
  }
  &__pointer {
    cursor: pointer;
  }
}
</style>
