import SharePromotionTag from './share-promotion-tag.vue'
import SharePromotionTagV2 from './share-promotion-tag-v2.vue'
import { getProps } from './helper'
import { createHelper } from '../../hooks/useHelper'

const useSharePromotionTag= createHelper('SharePromotionTag', getProps)

export {
  SharePromotionTag,
  SharePromotionTagV2,
  useSharePromotionTag,
}
