<template>
  <div
    v-if="isShow"
    :style="styles"
    class="bsc-cart-item-goods-discount-tag">
    <div class="bsc-cart-item-goods-discount-tag__item">
      {{ unitDiscount }}
    </div>
    <div
      v-if="isShowSaleDiscount"
      class="bsc-cart-item-goods-discount-tag__item">
      {{ saleDiscount }}
    </div>
  </div>
</template>

<script name="GoodsDiscountFlag" setup lang="ts">
import { computed } from 'vue'

/**
 * 商品行商品标签组件
 */

interface GoodsDiscountFlagProps {
  /**
   * 是否展示
   */
  isShow?: boolean
  /**
   * 是否展示遮罩
   */
  isShowMask?: boolean
  /**
   * 背景颜色
   */
  bgColor: string
  /**
   * 字体颜色
   */
  fontColor: string
  /**
   * 单项折扣内容
   */
  unitDiscount?: string
  /**
   * 特价折扣
   */
  saleDiscount?: string
  /**
   * 是否展示特价折扣
   */
  isShowSaleDiscount?: boolean
}

// props
const props = withDefaults(defineProps<GoodsDiscountFlagProps>(), {
  isShow: false,
  isShowMask: false,
  bgColor: '',
  fontColor: '',
  unitDiscount: '',
  saleDiscount: '',
  isShowSaleDiscount: false,
})

// computed
const styles = computed(() => ({
  '--bg-color': props.bgColor || '',
  '--font-color': props.fontColor || '',
  '--mask-opacity': props.isShowMask ? '0.3' : '1',
}))
</script>

<style lang="less">
.bsc-cart-item-goods-discount-tag {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: var(--bg-color);
  color: var(--font-color);
  margin-top: 2/37.5rem;
  padding: 0 2/37.5rem;
  opacity: var(--mask-opacity);
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32/37.5rem;
    height: 16/37.5rem;
    white-space: nowrap;
    direction: ltr /*rtl: ignore*/;
    flex-shrink: 0;
    &:nth-child(2) {
      text-decoration: line-through;
      opacity: 0.8;
    }
  }
}
</style>
